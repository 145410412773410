import { Input } from "antd";
import styled from "styled-components";

const { TextArea: TA } = Input;

const TextField = styled(Input)`
  width: 100%;
`;

const TextArea = styled(TA)`
  width: 100%;
`;

export { TextField, TextArea };
