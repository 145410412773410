import { IPlatformInterface, IUserCredentials, IUserV2 } from "flowy-3-core";
import auth from "./authentication";

class PlatformInterface implements IPlatformInterface {
  private _files: { [key: string]: File } = {};

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  isAuthenticated(): Promise<boolean> {
    return auth.isAuthenticated();
  }

  logout(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      await auth.logout();
      resolve();

      // eslint-disable-next-line no-restricted-globals
      // location.reload();
    });
  }

  getUser(): Promise<IUserV2> {
    return auth.getUser();
  }

  setUser(user: IUserV2): Promise<void> {
    return new Promise<void>(async (resolve) => {
      await auth.setUser(user);
      resolve();
    });
  }

  getUserCredentials(): Promise<IUserCredentials> {
    return auth.getUserCredentials();
  }

  fileSet(props: {
    path: string;
    fileFid: string;
    fileName?: string | undefined;
    fileExtension: string;
    base64file?: string | undefined;
    file?: File | undefined;
  }): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this._files[props.fileFid] = props.file as File;

      resolve();
    });
  }

  fileGet(props: {
    path: string;
    fileFid: string;
    fileExtension: string;
  }): Promise<{ file: File } | undefined> {
    return new Promise<{ file: File } | undefined>(async (resolve) => {
      resolve({ file: this._files[props.fileFid] });
    });
  }

  fileExists(props: { path: string; fileFid: string }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  fileRemove(props: { path: string; fileFid: string }): Promise<void> {
    return new Promise<void>(async (resolve) => {
      delete this._files[props.fileFid];
      resolve();
    });
  }

  fileCopy(props: {
    path: string;
    originalFileFid: string;
    originalFileExtension: string;
    newFileFid: string;
    newFileExtension: string;
  }): Promise<void> {
    return new Promise<void>(async (resolve) => {
      resolve();
    });
  }
}

const platformInterface = new PlatformInterface();
export default platformInterface;
